import React, { useContext } from "react";
import Styles from "./styles.module.scss";
import { AuthContext } from "../../../providers/AuthProvider";
import { CartContext } from "../../../providers/CartProvider";
import { useHistory } from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import Button from "../../../components/Button";
import backgroundImage from "../../../globals/assets/order_success.png";
import image from "../../../globals/assets/letter.svg";
import useQuery from "../../../hooks/useQuery";
import * as NotAuthRoutes from "../../../routers/NotAuthRouter/routes";
import { LocaleConsumer } from "../../../providers/LocaleProvider";
import API from "../../../helpers/API";

const OrderSuccess: React.FC = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const history = useHistory();
  const query = useQuery();
  const type = query.get("type");

  const disconnect = React.useCallback(() => {
    cartContext.empty();
    authContext.logout();
    history.push(NotAuthRoutes.default.Home);
  }, [authContext, cartContext, history]);

  const newOrder = React.useCallback(() => {
    cartContext.empty();
    if (!(authContext.user?.isRep || authContext.user?.isManager)) {
      history.push(ERoutes.Scanner);
    } else {
      const token = API.getTokenFromCookie!();
      const showroom = authContext.user.showroom;
      authContext.logout();
      history.push({
        pathname: NotAuthRoutes.default.SelectClient,
        state: {
          token: token,
          showroom: showroom,
        },
      });
    }
  }, [authContext, cartContext, history]);

  return (
    <LocaleConsumer>
      {({ getTranslation }) => (
        <BackgroundImageLayout image={backgroundImage}>
          <div className={Styles["orderSuccess"]}>
            <div className={Styles["orderSuccess__infos"]}>
              <img
                alt="logo"
                src={image}
                className={Styles["orderSuccess__img"]}
              />
              {type === "showroom" ? (
                <h2>{getTranslation("order_success_page.order_showroom")}</h2>
              ) : (
                <h2>{getTranslation("order_success_page.order_home")}</h2>
              )}
            </div>
            <span className={Styles["orderSuccess__orderNumber"]}>
              {getTranslation("order_success_page.order_number")}{" "}
              {cartContext.orderNumber}
            </span>
            <div className={Styles["orderSuccess__buttons"]}>
              <Button
                styleType="plain"
                size="large"
                type="button"
                label={getTranslation("order_success_page.disconnect")}
                onClick={disconnect}
                className={Styles["orderSuccess__buttonDisconnect"]}
              />
              <Button
                styleType="outlined"
                size="large"
                type="button"
                label={getTranslation("order_success_page.new_order")}
                onClick={newOrder}
              />
            </div>
          </div>
        </BackgroundImageLayout>
      )}
    </LocaleConsumer>
  );
};

export default OrderSuccess;
